<app-web-menu-bar></app-web-menu-bar>
<!-- header-end -->
<!-- main-area -->
<main style="background-color: #fff !important;">
    <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template" style="z-index: 500000; position: absolute;"> Loading... </ngx-loader>        

    <!-- slider-area -->
    <section id="home" class="slider-area fix p-relative">
               
        <div class="">
        <div class="single-slider slider-bg show-bg3 d-flex align-items-center" style="background-image: url('../../assets/website/homebg_new.jpg'); background-repeat: no-repeat;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="slider-content s-slider-content text-left" style="background-color: #f7f7f7;
                            padding: 20px;
                            border-radius: 10px; max-width: 500px; float: right;">
                                <h2 data-animation="fadeInUp" data-delay=".4s">Welcome to <span>NeoDigit</span></h2>
                                <p data-animation="fadeInUp" data-delay=".6s">At Neodigit,we are at the forefront of transforming the insurance industry with our innovative and cutting edge technology. Our pioneering Insurtech solutions are designed to meet the diverse needs of modern employee benefits.</p>
                                <div class="slider-btn mt-25">       
                                    <!-- <a routerLink="/survey-verify-email" class="btn ss-btn" href="#" data-animation="fadeInRight" data-delay=".8s">Take Survey <i class="fas fa-chevron-right"></i></a>                                    -->
                                    <a href="https://app.neodigit.in/login" target="_blank" class="btn ss-btn">Take Survey <i class="fas fa-chevron-right"></i></a>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">									
                        </div>
                    </div>
                </div>
            <!-- video -->
             <!-- <video id="my-video" class="video2" muted loop autoplay>
                <source src="../../assets/website/slider/slider-vedio.mp4" type="video/mp4">
                <source src="img/slider/slider-vedio.ogv" type="video/ogg">
                <source src="img/slider/slider-vedio.webm" type="video/webm">
            </video> -->
            <!-- /video -->
            </div>
            
            </div>
            
       
    </section>
    <!-- slider-area-end -->

    <div class="container mt-4">        
        <div class="section">
            <div class="section-title mb-10">
                <span> Explore our Comprehensive Suite of Insurance Solutions</span>
            </div>
            
            <ul>
                <li class="highlight"><b>Employee Benefits Portal:</b> Your one-stop destination for workforce management.</li>
                <li class="highlight"><b>OPD & Flex Benefits:</b> OPD & Flexible insurance options designed to adapt to the evolving needs of employees.</li>
                <li class="highlight"><b>Analytic Tool:</b> Unlock data-driven insights to drive business success and employee satisfaction.</li>
                <li class="highlight"><b>AI-Powered WhatsApp Chat-bot:</b> Instant service at your fingertips.</li>
                <li class="highlight"><b>Survey:</b> Transforming employee feedback into meaningful outcomes.</li>
                <li class="highlight"><b>Affinity Solution:</b> Exclusive insurance products crafted for affinity groups and their members.</li>
            </ul>
        </div>

        <div class="section mt-3">
            <p>We are committed to transforming the insurance landscape by leveraging cutting-edge technology to address complex, real-world challenges. Our team of visionary experts, spanning Experienced Insurance professionals, software architecture, AI and ML, big data analytics, unites to create bespoke solutions that surpass client expectation.</p>
            <p>Through our collaborative and innovative approach, we are committed to pioneering advancements in the Insurtech sector. We invest heavily in R&D, continuously exploring emerging technologies to stay ahead of the curve. Our finger-on-the-pulse approach ensures we're always attuned to the latest industry trends, empowering us to deliver unparalleled value to our clients and maintain a leadership position in a dynamic insurance market.</p>
        </div>

        <div class="section">
            <p>With a passion for innovation and a forward-thinking mind-set, we're dedicated to developing ground-breaking solutions that reshape the insurance industry for a better future. We are redefining the insurance landscape with technology-driven solutions that emphasize flexibility, customization, and cost efficiency.</p>
        </div>

        <div class="section">
            <img src="../../assets/website/eb_portal.png" style="margin:auto; display: block; width: 70%;" />
        </div>

    </div>
    
    <!-- services-area -->
    <!-- <section id="services" class="services-area services-bg services-two pt-100"
        style="background-image:url(../../assets/website/an-bg/an-bg02.png); background-size: contain; background-repeat: no-repeat;background-position: center center;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-8">
                    <div class="section-title text-center pl-40 pr-40 mb-80">
                        <span> our services</span>
                        <h2>Our Special Services For You</h2>
                    </div>
                </div>
            </div>
            <div class="row sr-line">
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center active">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/sr-icon01.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Health & Benefits Tool</a></h5>
                            <p>
                                1. Enrolment Portal<br>
                                2. OPD Plan<br>
                                3. Flex Plan<br>
                                4. Claim Management
                            </p>                            
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/sr-icon02.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Benefits Consulting</a></h5>
                            <p>
                                1. Benefits & Claim Benchmarking<br>
                                2. Claim Utilization Report (CUR) & Trend Analysis<br>
                                3. Employee Survey
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/sr-icon03.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Benefits Harmonization</a></h5>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/cunt-icon01.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Automation (Service & Operations)</a></h5>
                            <p>
                                1. AI Supported WhatsApp Chat bot<br>
                                2. IVR & Voice Bot<br>
                                3. RPA
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/cunt-icon02.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Communication</a></h5>
                            <p>
                                1. Single Pager - Benefits<br>
                                2. Benefits Manual – Voice<br>
                                3. Customized communication<br>
                                4. Enrolment Process Communication<br>
                                5. Audio & Video Tool
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/cunt-icon03.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Wellbeing</a></h5>
                            <p>
                                1. HRA<br>
                                2. Wellness Tips<br>
                                3. Wellness Partner Management
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/cunt-icon04.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Affinity Solution</a></h5>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/de-icon01.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Property & Construction Risk Solutions</a></h5>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/de-icon02.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Marine Solutions</a></h5>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                    <div class="s-single-services text-center">
                        <div class="services-icon">
                            <img src="../../assets/website/icon/de-icon03.png" alt="img">
                        </div>
                        <div class="second-services-content">
                            <h5><a href="services-detail.html">Business Operations Solutions</a></h5>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </section> -->
    <!-- services-area-end -->        
</main>
<!-- main-area-end -->
<!-- footer -->
<app-web-footer></app-web-footer>
<!-- footer-end -->